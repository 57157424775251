jQuery(document).ready(function($) {
  var isSyncing = false;

  function syncLanguage(desktopSelector, mobileSelector) {
    // Sync from desktop to mobile
    $(desktopSelector).on("click", "a.nturl", function(event) {
      if (isSyncing) return; // Exit if already syncing
      isSyncing = true;
      var lang = $(this).data("gt-lang");
      event.preventDefault();

      // Find the corresponding mobile link and simulate a click
      var mobileLink = $(mobileSelector).find(
        'a.nturl[data-gt-lang="' + lang + '"]',
      )[0];
      mobileLink.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );

      isSyncing = false;
    });

    // Sync from mobile to desktop
    $(mobileSelector).on("click", "a.nturl", function(event) {
      if (isSyncing) return; // Exit if already syncing
      isSyncing = true;
      var lang = $(this).data("gt-lang");
      event.preventDefault();

      // Find the corresponding desktop link and simulate a click
      var desktopLink = $(desktopSelector).find(
        'a.nturl[data-gt-lang="' + lang + '"]',
      )[0];
      desktopLink.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        }),
      );

      isSyncing = false;
    });
  }

  setTimeout(function() {
    syncLanguage(".desktop-menu", ".translate-mobile");
    console.log("called");
    $('a.nturl[data-gt-lang="fr"]').append(" (traduction automatique)");
    $('a.nturl[data-gt-lang="es"]').append(" (traducción automática)");
    console.log("called");
  }, 2000);
});
